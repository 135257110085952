@import "./utils/_variables.scss";

// .test-enter {
//   background-color: blue !important;
//   opacity: 0;

//   &.test-enter-active {
//     opacity: 1;
//   }
// }

// .test-exit {
//   opacity: 1;

//   &.test-exit-active {
//     opacity: 0;
//   }
// }

.btn-grey {
  background: rgba(227, 227, 227, 0.5);
}

.table-responsive {
  min-height: 60vh;
  opacity: 1;
  transition: opacity 0.1s ease-in;
  &.loading {
    opacity: 0;
    transition: opacity 0.1s ease-in;
  }
}

#react-select-1-listbox,
#react-select-2-listbox,
#react-select-3-listbox,
#react-select-4-listbox {
  z-index: 999;
}

.test-wrapper {
  // opacity: 1;
  transition: all 0.3s ease;
  // position: relative;
  &.test-enter-done {
    opacity: 1;
    // left: 0;
    // top: 0;
    // transform: translateY(0);
    transition: all 0.3s ease;
    // transition: top 0.3s ease-in;
  }

  &.test-enter-active {
    opacity: 0;
    // top: 30vh;

    // transform: translateY(30vh);
    // left: 30vw;
    transition: all 0.3s ease;
    // transition: top 0.3s ease;
  }

  &.test-exit-active {
    opacity: 0;
    // transform: translateY(30vh);
    transition: opacity 0.3s ease;
    // left: 30vw;
    // top: 30vh;
  }

  // &.test-exit {
  //   opacity: 0;
  //   // left: 30vw;
  //   // top: 30vh;
  //   transition: all 0.3s ease;
  // }
}
// LOADER

._loading_overlay_overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw !important;
  height: 100vh !important;
  position: fixed !important;
}

.__react_modal_image__modal_container {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.__react_modal_image__header {
  background-color: $white !important;
  .__react_modal_image__caption {
    color: $black !important;
  }

  svg {
    fill: $black;
  }
}

.cursor-pointer:hover {
  cursor: pointer;
}

.date-separator {
  margin-bottom: 1.5em;
  p {
    background-color: $light-gray;
    padding: 3px 15px !important;
    border-radius: 5px;
  }
}

.max-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

//CANONICAL
.canonical-card {
  border-radius: 10px;
  overflow: hidden;
  margin: 5px;

  .link-info {
    width: 75%;
    background-color: $card-body-color;
    padding-left: 1em;
    .link-title,
    .link-description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .link-url {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .img-wrapper {
    width: 25%;
    img {
      // width: 100%;
      max-width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }
}

input::placeholder {
  opacity: 0.6 !important;
}

.input-password {
  .input-group-append {
    position: absolute;
    right: 0;
    width: 3em;
    height: 100%;
    // background-color: red !important;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5em;
    z-index: 10;
    &:hover {
      cursor: pointer;
    }
  }
}

// AVATAR HELPER
.avatar-helper {
  width: 40px;
  height: 40px;
  border-radius: 40px;

  margin-left: -10%;
  border: 2px solid #fff;
  box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  background-color: indigo;
  line-height: 38px;
  color: white;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.avatar-group {
  margin-left: 8%;
}

.avatar-group li {
  margin-left: -8%;
}

.avatar-group li:first-child .avatar-helper {
  background-color: violet;
  color: white;
}

.avatar-group li:nth-child(2) .avatar-helper {
  background-color: yellowgreen;
  color: white;
}

.avatar-group li:nth-child(3) .avatar-helper {
  background-color: gray;
  color: white;
}

.status-connected {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: $success-color;
  position: absolute;
  right: 15%;
  bottom: 15%;
}

.status-disconnected {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: $danger-color;
  position: absolute;
  right: 15%;
  bottom: 15%;
}

.form-radio-custom {
  box-shadow: 5px 5px 5px 0px rgba(241, 241, 241, 0.4);
  background-color: white;
  border: 1px solid rgba(235, 235, 235, 0.479);
  border-radius: 5px;
  padding: 0.8em 2.5em;
  transition: all 0.2s ease;

  &:hover {
    background-color: $app-bg-light-color;
    transition: all 0.2s ease;
  }

  &:hover,
  &:hover label,
  &:hover input {
    cursor: pointer;
  }
}

.customizer-links {
  display: none !important;
}

label.required:after {
  color: red;
  content: " *";
}

table.generic-view {
  width: 100%;
  tr td:first-child {
    padding-left: 1em;
    width: 20%;
  }

  tr td:last-child {
    width: 80%;
  }

  tr td:last-child:before {
    content: " : ";
    padding-left: 1em;
  }

  td {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }

  tr {
    border-bottom: 1px solid #f0f0f06b;
  }
}

.font-bold {
  font-weight: bold;
}

.my-message.float-right {
  // background-color: $dark-color;
}

table.generic-list {
  // margin: 1em 2em;
  padding-left: 2em;

  thead tr {
    background-color: $white;
  }

  thead tr th {
    font-weight: bold;
    border-top: 1px solid $light-gray !important;
  }

  // thead tr th:first-child {
  //   text-align: center;
  // }

  tr th span {
    color: $dark-gray;
    text-transform: capitalize;
  }

  tr td span,
  tr th span {
    font-family: $font-roboto, $font-serif !important;
  }

  tr td {
    border-top: none;
    border-bottom: none;
  }

  .action-button {
    position: absolute;
    visibility: visible;
    display: flex;
    flex-direction: row;
    right: 0;
    justify-content: space-around;
    top: 50%;
    transform: translateY(-50%);

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2em;
      background-color: $app-bg-light-color !important;
    }
  }

  tbody tr {
    background-color: white;
    transition: all 0.2s ease;
  }

  tbody tr:hover {
    .action-button {
      visibility: visible;
    }
    background-color: rgba(201, 201, 201, 0.199);
    transition: all 0.2s ease;
  }

  tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
}

.generic-list-page {
  .table-header {
    margin-bottom: 2em;
    .clear-button {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(-50%, -50%);
      color: $dark-gray;
    }
  }
}

table.table-responsive .permission-table {
  tr th {
    justify-content: center;
    text-align: center;
  }

  tr td input {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}

.chat-image {
  border-radius: 7px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.react-datepicker__month-container {
  box-shadow: 2px 3px 9px -1px rgba(148, 148, 148, 0.281);
  -webkit-box-shadow: 2px 3px 9px -1px rgba(148, 148, 148, 0.281);
  -moz-box-shadow: 2px 3px 9px -1px rgba(148, 148, 148, 0.281);

  border: 1px solid $gray-60;
  border-radius: 7px;
}

.react-datepicker .react-datepicker__day--today {
  background-color: #dddddd !important;
}
