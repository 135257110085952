@import "../../assets/scss/utils/variables.scss";

.broadcast-view-page {
  .contact-list {
    border-left: 1px solid $card-body-color;
  }

  .contact-wrapper {
    border-bottom: 1px solid $card-body-color;
  }
}
