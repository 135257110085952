.switch-wa-modal {
  .form-check {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0 !important;
    margin-left: 0 !important;

    margin: 0.5em;
  }

  .form-checked-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $light-gray;
    width: 100%;
    // background: lightblue;
    padding: 0.8em 1em;
    margin-left: 0.8em;
    padding-left: 1em !important;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background: rgba($primary-color, 0.1);
      margin-bottom: 0.5em;
    }
  }

  .form-check-input {
    position: static;
    margin-left: 0 !important;
    width: 1.3em;
    height: 1.3em;
    margin-top: 0 !important;
    margin-right: 0.5em !important;
  }
}

.switch-whatsapp {
  background: $card-body-color !important;
  outline: none !important;
  border: none !important;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(0, 150, 150, 0.2) !important;
  }

  &:focus {
    background: rgba(0, 150, 150, 0.2) !important;
  }

  &:active {
    background: rgba(0, 150, 150, 0.2) !important;
  }
  p {
    margin: 0 !important;
    padding: 0 !important;
    color: $primary-color !important;
  }

  i {
    color: rgba(0, 150, 150, 0.9) !important;
    margin-left: 0.25em;
  }
}

.modal-backdrop-default {
  background: rgba(10, 10, 10, 0.8) !important;
}

.contact-list-page {
  table.contact-table {
    .action-button,
    .action-button button {
      //   z-index: 10000;
    }
    thead {
      th {
        border-top: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
        font-weight: bold;
      }

      tr th:first-child {
        text-align: center;
      }
    }

    tbody tr {
      background-color: white;
      transition: all 0.3s ease;
      &:nth-child(odd) {
        background-color: #f9f9f9;
      }
      &:hover {
        background-color: #eae8ff;
        cursor: pointer;
      }
      &.active {
        background-color: #eae8ff !important;
        transition: all 0.3s ease;
      }

      td {
        border-top: 0;
        border-bottom: 0;
      }
    }

    thead tr th:nth-child(2),
    tbody tr td:nth-child(2) {
      max-width: 2.5em;
    }

    tbody tr td:first-child {
      width: 1em;
    }
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .list-wrapper {
    width: 100%;
    transition: all 0.4s ease;
    &.half {
      width: 50%;
      transition: all 0.4s ease;
    }
  }

  .detail-wrapper {
    width: 0%;
    transition: all 0.4s ease;
    opacity: 0;
    padding: 2em 0;

    &.show {
      width: 50%;
      transition: all 0.4s ease;
      opacity: 1;
      padding: 2em 2em;
    }

    .detail-header {
      border-bottom: 1px solid grey;
    }
  }
}

.permission-page {
  .form-check-input {
    position: static !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    width: 1.1em;
    height: 1.1em;
  }

  tr th:not(:first-child),
  tr td:not(:first-child) {
    text-align: center;
  }

  tr th {
    background-color: $app-bg-light-color;
  }

  tr:first-child th {
    border-bottom: 0;
  }

  tr:nth-child(2) th {
    border-top: 0;
  }

  tr:nth-child(2) th {
    border-bottom: 0;
  }
}

.connect-whatsapp {
  // margin-top: 8em !important;
  .qr-inner {
    background-color: #eae8ff;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 0;

    &.loading {
      opacity: 1;
    }
  }

  .qr-error {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 0;

    button {
      width: 70%;
      height: 70%;
      border-radius: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1em 1.5em;
      cursor: pointer;
    }

    &.show {
      opacity: 1;
      z-index: 100;
    }
  }
}

.live-chat {
  .card {
    margin-top: 2em !important;
  }
  .chat-sidebar .chat-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .chat-sidebar .chat-body {
    height: 80vh !important;
  }

  .chat-sidebar .people-list .list {
    max-height: calc(80vh - 10rem) !important;

    overflow-y: scroll;
    overflow-x: auto;

    .member-list.active {
      background-color: rgba(199, 199, 199, 0.521);
    }
  }

  .chat-sidebar .people-list .about {
    max-width: 60%;
    
  }

  .member-list-time {
    position: absolute;
    right: 0;
    color: $dark-gray;
    font-size: 0.9em;
    top: 5px;
  }

  .chat-message {
    width: 100%;
  }

  .chat-right-aside {
    height: 80vh;

    .chat-msg-box {
      height: calc(80vh - 9rem);
      overflow-y: scroll;
    }
  }

  .people-list-header {
    padding-left: 40px;
    padding-right: 40px;
  }
  .member-list {
    padding-bottom: 0.8em !important;
    padding-top: 0.8em !important;
    padding-left: 2em;
    padding-right: 2em;
    transition: all 0.25s ease;

    &:hover {
      background: rgba(194, 194, 194, 0.212);
      cursor: pointer;
      transition: all 0.25s ease;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(201, 201, 201, 0.123);
    }
  }
}

.connection-waiting {
  margin-top: 10em !important;
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60vh;
    align-items: center;
  }

  i {
    font-size: 5em;
    color: rgba(206, 206, 206, 0.493);
  }

  .progress {
    width: 40%;
    height: 4px !important;
    margin-bottom: 1em;
  }
}

.login-main.login-tab {
  position: absolute !important;
  width: 50vw;
  top: 50%;
  height: auto !important;

  left: -50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;

  &.show {
    opacity: 1;
    left: 50%;
    transition: all 0.5s ease;
  }
}

.login-card {
  .input-password .input-group-prepend {
    position: absolute;
    width: 40px;
    height: 100%;
    right: 0;
    line-height: 38px;
    top: 0;
    // top: 38px;

    &:hover {
      cursor: pointer;
    }

    i {
      font-size: 1.4em;
    }
  }
}

.signup-card {
  position: absolute;
  width: 50vw;
  left: 150%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;

  &.show {
    opacity: 1;
    left: 50%;
    transition: all 0.5s ease;
  }
}

table.message-table {
  .action-button {
    position: absolute;
    visibility: visible;
    display: flex;
    flex-direction: row;
    right: 10px;
    justify-content: space-around;
    top: 50%;
    transform: translateY(-50%);

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2em;
      background-color: $app-bg-light-color !important;
    }
  }

  tbody tr td:first-child,
  thead tr th:first-child {
    padding-left: 2.5em;
  }

  thead tr th {
    font-weight: bold;
    border-top: 1px solid $light-gray !important;
  }

  tbody {
    tr {
      td:first-child {
        max-width: 10%;
      }

      td:nth-child(4) {
        width: 40%;
      }

      td:nth-child(3) {
        width: 15%;
      }
    }
  }
}
